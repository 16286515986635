import { FloatingFocusManager, FloatingPortal, useMergeRefs } from "@floating-ui/react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslations } from "next-intl";
import { forwardRef, HTMLProps, ReactNode, useMemo } from "react";

import { useBreakpoint } from "~/contexts/breakpoint";
import { useCartNotification } from "~/contexts/cart-notification";
import { useCart } from "~/hooks/use-cart";
import { basicAnimationStates } from "~/utils/animation-utils";
import appRoutes from "~/utils/app-routes";
import Constants from "~/utils/constants";
import useScrollDirection from "~/utils/use-scroll-direction";

import CartProducts from "../cart/cart-products";
import CartSubtotals from "../cart/cart-subtotals";
import Button from "../common/button";
import Container from "../common/container";
import Grid from "../common/grid";
import HighlightedText from "../common/highlighted-text";
import Icon from "../common/icon";
import LinkWrapper from "../common/link-wrapper";
import { PagePopupClose, usePagePopupContext } from "../common/page-popup";
import Pane from "../common/pane";
import pagePopupStyles from "./../common/page-popup.module.scss";
import styles from "./mini-cart.module.scss";

export default forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(function MiniCart(props, propRef) {
  const t = useTranslations();
  const breakpoint = useBreakpoint();
  const { context: floatingContext, ...context } = usePagePopupContext();
  const { isHovering, setHovering, state: notificationState, resetNotifications } = useCartNotification();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);
  const cart = useCart();

  const lastProduct = useMemo(() => {
    if (!cart) return null;

    const allLines = cart.ShoppingCart.Lines;
    const productLines = allLines.filter((line) => !!line.ProductID);

    if (productLines.length === 0) {
      return null;
    }

    return productLines.sort((a, b) => {
      const aTime = a.LastModificationTime ?? a.CreationTime;
      const bTime = b.LastModificationTime ?? b.CreationTime;

      return bTime.localeCompare(aTime);
    })[0];
  }, [cart]);

  const shouldShowNotification = useMemo(() => {
    if (isHovering) {
      return false;
    }

    switch (notificationState.status) {
      case "removed":
      case "addedAll":
      case "modified":
      case "fixed":
      case "oos":
        return true;
      case "added":
        if (!lastProduct) return false;
        const cartTimestamp = lastProduct.LastModificationTime ?? lastProduct.CreationTime;
        return cartTimestamp > notificationState.timestamp.toISOString();
      default:
        return false;
    }
  }, [isHovering, notificationState, lastProduct]);

  const onClose = () => {
    setHovering(false);
    resetNotifications();
  };

  const getMessage = (): ReactNode => {
    switch (notificationState.status) {
      case "removed":
        return (
          <p className={styles.desc}>
            <HighlightedText text={t("generic.productremoved")} />
          </p>
        );
      case "added":
        return (
          <p className={styles.desc}>
            {t.rich("generic.productadded", {
              strong: (chunks) => <strong>{chunks}</strong>,
              0: lastProduct?.Product?.Properties?.display_value,
            })}
          </p>
        );
      case "modified":
        return (
          <p className={styles.desc}>
            {t.rich("generic.productQuantityModified", {
              strong: (chunks) => <strong>{chunks}</strong>,
              productName: lastProduct?.Product?.Properties?.display_value,
            })}
          </p>
        );
      case "fixed":
        return (
          <>
            <p className={styles.desc}>
              {t.rich("generic.productQuantityModified", {
                strong: (chunks) => <strong>{chunks}</strong>,
                productName: lastProduct?.Product?.Properties?.display_value,
              })}
            </p>
            <p className={styles.warningMessage}>
              <Icon name="info" width={16} height={16} />
              <span>
                {t.rich("generic.productQuantityFixed", {
                  strong: (chunks) => <strong>{chunks}</strong>,
                  productName: lastProduct?.Product?.Properties?.display_value,
                })}
              </span>
            </p>
          </>
        );
      case "oos":
        return (
          <>
            <p className={styles.desc}>
              {t.rich("generic.productQuantityModified", {
                strong: (chunks) => <strong>{chunks}</strong>,
                productName: lastProduct?.Product?.Properties?.display_value,
              })}
            </p>
            <p className={styles.warningMessage}>
              <Icon name="info" width={16} height={16} />
              <span>
                {t.rich("generic.productQuantityOOS", {
                  strong: (chunks) => <strong>{chunks}</strong>,
                  productName: lastProduct?.Product?.Properties?.display_value,
                })}
              </span>
            </p>
          </>
        );
      default:
        return (
          <strong>
            {t("generic.addedAllProducts")}
            {notificationState.status === "addedAll" ? <>{notificationState.moreText}</> : null}
          </strong>
        );
    }
  };

  const { dir, y } = useScrollDirection({
    initialDirection: "up",
    thresholdPixels: breakpoint == "desktop" ? Constants.HEADER_HEIGHT_DESKTOP : Constants.HEADER_HEIGHT_MOBILE,
  });

  return (
    <FloatingPortal>
      <FloatingFocusManager context={floatingContext}>
        <AnimatePresence>
          {(isHovering || notificationState.status !== undefined) && (
            <motion.div
              ref={ref}
              initial={basicAnimationStates.off}
              animate={basicAnimationStates.on}
              exit={basicAnimationStates.off}
              className={classNames(
                pagePopupStyles.pagePopup,
                styles.pagePopup,
                { [pagePopupStyles.scrolledTotal]: dir === "down" },
                { [pagePopupStyles.scrolledTop]: y >= Constants.HEADER_HEIGHT_DESKTOP }
              )}
              {...context.getFloatingProps(props)}
            >
              <Container>
                <Grid>
                  {isHovering ? (
                    <Pane className={classNames(pagePopupStyles.panel, styles.minicartPanel)}>
                      <div className={styles.minicartTop}>
                        {t(
                          cart && !cart.ShoppingCart.IsPaid && cart.ShoppingCart.TotalItems > 0
                            ? "generic.cart.itemscount"
                            : "generic.cart.empty.title",
                          {
                            0: cart?.ShoppingCart.TotalItems,
                          }
                        )}
                        <PagePopupClose className="sr-only"></PagePopupClose>
                      </div>
                      <div className={styles.minicartContainer}>
                        <CartProducts
                          classNames={styles.minicartList}
                          disableCartActions
                          trackingScope="mini-cart"
                          inMinicart
                        />
                        <CartSubtotals
                          onClose={onClose}
                          emptyCart={cart == undefined || cart?.ShoppingCart.TotalItems == 0}
                        />
                      </div>
                    </Pane>
                  ) : shouldShowNotification ? (
                    <Pane className={classNames(pagePopupStyles.panel, styles.notifyPanel)}>
                      <div className={styles.topLine}>
                        {getMessage()}
                        <button onClick={onClose} className={styles.close} aria-label={t("generic.close")}>
                          <Icon name="close" />
                        </button>
                      </div>
                      <div className={styles.bottomLine}>
                        <Button onClick={onClose} lookAsLink>
                          {t("generic.continueshopping")}
                        </Button>
                        <LinkWrapper
                          href={appRoutes.CART}
                          onClick={onClose}
                          lookAsButton="primary"
                          className={styles.minicartButton}
                        >
                          {t("generic.gotobag", {
                            0:
                              cart && !cart.ShoppingCart.IsPaid && cart.ShoppingCart.TotalItems > 0
                                ? `(${cart?.ShoppingCart.TotalItems})`
                                : " ",
                          })}
                        </LinkWrapper>
                      </div>
                    </Pane>
                  ) : null}
                </Grid>
              </Container>
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingFocusManager>
    </FloatingPortal>
  );
});
