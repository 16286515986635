import { hooks, state } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import classNames from "classnames";
import { useTranslations } from "next-intl";
import { Fragment, useEffect, useMemo, useState } from "react";

import CartEmpty from "~/components/cart/cart-empty";
import { useAuthContext } from "~/contexts/auth";
import { useWishlist } from "~/contexts/wishlist";
import { useCart, useCartAvailability, useCartProductsHierarchicalData } from "~/hooks/use-cart";
import useGiftcard from "~/hooks/use-giftcard";
import Editorials from "~/types/editorials";

import CartLoginDialog from "./cart-login-dialog";
import CartProductTile from "./cart-product-tile";
import styles from "./cart-products.module.scss";
import CartUndoItem from "./cart-undo-item";

type Props = {
  classNames?: string;
  disableCartActions?: boolean;
  trackingScope?: string;
  emptyLinks?: Editorials.CartPage["emptyLinks"];
  inMinicart?: boolean;
};

export type UndoItem = {
  index: number;
  product: number;
  productName: string;
  quantity: number;
  removedAction: "fromCart" | "fromSfl" | "toWishlist" | "toSfl";
  destinationList?: number | string;
};

type cartLine = EVA.Core.OrderLineDto | UndoItem;

export default function CartProducts(props: Props) {
  const shoppingCartAdd = hooks.useShoppingCartAdd({ service: Core.AddProductToOrder });

  const t = useTranslations();

  // Hooks for accessing cart, cart availability, and authentication status
  const cart = useCart();
  const linesProductsDetails = useCartProductsHierarchicalData();
  const cartAvailability = useCartAvailability();
  const { isAuthenticated } = useAuthContext();
  const { removeProdFromWishlist, savedForLaterList } = useWishlist();

  // Hook to refresh cart recoil state when mounting the component
  const setShoppingCartStale = hooks.useSetServiceStale({ serviceState: state.checkout.shoppingCartState });

  // State for managing undo list and login modal state
  const [undoList, setUndoList] = useState<UndoItem[]>([]);
  // Extract lines from the shopping cart and filter out lines without products
  const lines = cart?.ShoppingCart.Lines ?? [];
  const linesWithProducts = lines.filter((line) => !!line.ProductID);

  const [loginModalOpen, setLoginModalOpen] = useState<boolean>(false);

  // Automatically close the login modal if the user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      setLoginModalOpen(false);
    }
  }, [isAuthenticated]);

  // Refresh the recoil cart state when mounting the component
  useEffect(() => {
    setShoppingCartStale();
  }, [setShoppingCartStale]);

  // Combine cart lines and undo list to create the final list of items
  const combinedList = useMemo(() => {
    let list: cartLine[] = [];
    list = [...linesWithProducts];
    undoList.forEach((element) => {
      const sameProductExistInCart = list.find((line) => "ProductID" in line && line.ProductID == element.product);
      const sameProductExistInSfl = savedForLaterList?.Products?.find((line) => line.ProductID == element.product);
      if (!sameProductExistInCart && !sameProductExistInSfl) {
        list.splice(element.index, 0, element);
      }
    });
    return list;
  }, [linesWithProducts, savedForLaterList?.Products, undoList]);

  const digitalGiftCardsQuery = useGiftcard().prepareGetDigitalGiftcardOptionsQuery(cart?.ShoppingCart);

  // Handler to remove an item and add it to the undo list
  const removeHandler = (
    id: number,
    name: string,
    index: number,
    quantity: number,
    outOfStockDelivery: boolean = false
  ) => {
    if (outOfStockDelivery) {
      return;
    }
    setUndoList((prevUndoList) => {
      // check if same product id is already in list
      if (prevUndoList.some((el) => el.product == id)) {
        return prevUndoList;
      }
      return [
        ...prevUndoList,
        {
          product: id,
          productName: name,
          index: index,
          removedAction: "fromCart",
          quantity,
        },
      ];
    });
  };

  // Handler to remove an item from the undo list
  const removeUndoItem = (id: number) => {
    const undoItemToRemove = undoList.find((item) => item.product == id);
    setUndoList((prevUndoList) => prevUndoList.filter((el) => el != undoItemToRemove));
  };

  // Handler for moving an item to the wishlist and adding it to the undo list
  const movedToWishlistHandler = (id: number, productName: string, itemIndex: number, quantity: number) => {
    setUndoList((prevUndoList) => {
      // check if same product id is already in list
      if (prevUndoList.some((el) => el.product == id)) {
        return prevUndoList;
      }
      return [
        ...prevUndoList,
        {
          product: id,
          productName,
          index: itemIndex,
          removedAction: "toSfl",
          quantity,
        },
      ];
    });
  };

  // Handler for undoing an action to move an item from the wishlist to the cart
  const undoFromWishlistToCart = async (id: number, quantity?: number) => {
    if (savedForLaterList) {
      await removeProdFromWishlist(id, savedForLaterList.ID);
    }
    await shoppingCartAdd({
      orderType: 0,
      payload: {
        ProductID: id,
        QuantityOrdered: quantity || 1,
      },
    });
    removeUndoItem(id);
  };

  // Handler for undoing an action to move an item from removed to the cart
  const undoFromRemovedToCart = async (id: number, idx: number, quantity?: number) => {
    await shoppingCartAdd({
      orderType: 0,
      payload: {
        ProductID: id,
        QuantityOrdered: quantity || 1,
      },
    });
    removeUndoItem(id);
  };

  // Handler to open the login modal
  const openLoginHandler = () => {
    setLoginModalOpen(true);
  };

  // Check if the cart is empty or paid and display the appropriate content
  // removing check on isPaid status since it shoud not occur anymore with the updated SDK
  if (combinedList.length == 0) {
    if (props.inMinicart) {
      return <></>; // Return nothing if the cart is paid or empty (in minicart)
    } else {
      return <CartEmpty emptyLinks={props.emptyLinks} />; // Render a CartEmpty component for non-minicart views
    }
  }

  return (
    <>
      <div className={classNames(styles.container, props.classNames)}>
        {combinedList.map((line, idx) => {
          // standard product line
          if ("ProductID" in line) {
            const isProductInStockDelivery = line.IsDigitalGiftCard
              ? true
              : cartAvailability?.Products.find((p) => p.ProductID === line.ProductID)?.Delivery?.HasStock;

            const isProductInStockPickup = line.IsDigitalGiftCard
              ? true
              : cartAvailability?.Products.find((p) => p.ProductID === line.ProductID)?.Pickup?.HasStock;

            return (
              <Fragment key={line.ID}>
                {idx !== 0 && <div className={styles.divider} aria-hidden />}
                <CartProductTile
                  outOfStockDelivery={isProductInStockDelivery === false}
                  outOfStockPickup={isProductInStockPickup === false}
                  line={line}
                  disableCartActions={props.disableCartActions}
                  onRemove={removeHandler}
                  index={idx}
                  movedToWishlist={movedToWishlistHandler}
                  onOpenLogin={openLoginHandler}
                  giftCardData={digitalGiftCardsQuery.data?.find((card) => card.Identifiers.OrderLineID === line.ID)}
                  rootProductDetails={linesProductsDetails?.[line.Product?.ID || ""]}
                  physicalGiftCardRequirement={
                    cart?.AdditionalOrderData?.ProductRequirements?.find((el) => el.OrderLineID == line.ID)
                      ?.RequirementModels
                  }
                  trackingScope={props.trackingScope}
                  minicart={props.inMinicart}
                />
              </Fragment>
            );
          } else if ("removedAction" in line) {
            return (
              <Fragment key={line.product}>
                {idx !== 0 && <div className={styles.divider} aria-hidden />}
                <CartUndoItem
                  undoItem={line}
                  undoAction={() => {
                    if (line.removedAction === "toSfl") {
                      undoFromWishlistToCart(line.product, line.quantity);
                    } else {
                      undoFromRemovedToCart(line.product, line.index, line.quantity);
                    }
                  }}
                  removeUndoItem={() => removeUndoItem(line.product)}
                />
              </Fragment>
            );
          }
        })}
      </div>
      {!props.inMinicart && <CartEmpty hasContent={false} emptyLinks={props.emptyLinks} />}
      <CartLoginDialog isOpen={loginModalOpen} onClose={() => setLoginModalOpen(false)} />
    </>
  );
}
