import { AutocompleteState, BaseItem, createAutocomplete } from "@algolia/autocomplete-core";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches";
import classNames from "classnames";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import React, { useEffect, useMemo, useState } from "react";

import Icon from "~/components/common/icon";
import { popularPlugin } from "~/components/search/plugins/popular-plugin";
import { productsPlugin } from "~/components/search/plugins/products-plugin";
import { searchClient } from "~/components/search/search-client";
import styles from "~/components/search/search-dialog.module.scss";
import { AlgoliaContext } from "~/contexts/algolia";
import { useBreakpoint, useWindowSize } from "~/contexts/breakpoint";
import { useConfiguration } from "~/contexts/configuration";
import { highlightToHtml } from "~/services/algolia-utils";

type AlgoliaProviderProps = {
  children: React.ReactNode;
  isOpen: boolean;
  setOpen: () => void;
  onClose: () => void;
};

export const AlgoliaContextProvider: React.FC<AlgoliaProviderProps> = ({ children, isOpen, setOpen, onClose }) => {
  const t = useTranslations();
  const router = useRouter();
  const { algoliaIndexId } = useConfiguration();
  const { width } = useWindowSize();
  const breakpoint = useBreakpoint();

  const [autocompleteState, setAutocompleteState] = useState<AutocompleteState<BaseItem> | null>(null);

  const suggestionsLimit = useMemo(() => {
    return breakpoint == "desktop" ? 5 : 3;
  }, [breakpoint]);

  const plugins = useMemo(() => {
    if (!searchClient && !algoliaIndexId) {
      return [];
    }

    const recentSearches = createLocalStorageRecentSearchesPlugin({
      key: "search",
      limit: suggestionsLimit,
      transformSource({ source, onRemove, onTapAhead }) {
        return {
          ...source,
          onSelect({ setIsOpen, event }) {
            if (!(event.target as HTMLElement).parentElement?.classList.contains("remove-item")) {
              setIsOpen(true);
            }
          },
          templates: {
            ...source.templates,
            item(params) {
              const { item } = params;
              return (
                <div className={classNames(styles.searchItem, styles.upp)} onClick={() => onTapAhead(item)}>
                  <p>{item.label}</p>
                  <button title={t("generic.fillQueryWith", { 0: item.label })} onClick={() => onTapAhead(item)}>
                    <Icon name="search-arrow" aria-hidden />
                  </button>
                  <button
                    title={t("generic.removeRecent")}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onRemove(item.id);
                    }}
                    className="remove-item"
                  >
                    <Icon name="trash" className="remove-item" aria-hidden />
                  </button>
                </div>
              );
            },
          },
        };
      },
    });

    const querySuggestions = createQuerySuggestionsPlugin({
      searchClient,
      indexName: `${algoliaIndexId}_query_suggestions`,
      getSearchParams() {
        return {
          hitsPerPage: suggestionsLimit,
        };
      },
      transformSource({ source, onTapAhead }) {
        return {
          ...source,
          onSelect({ setIsOpen }) {
            setIsOpen(true);
          },
          templates: {
            ...source.templates,
            item(params) {
              const { item } = params;
              return (
                <div className={styles.searchItem} onClick={() => onTapAhead(item)}>
                  {item._highlightResult?.query ? (
                    <p
                      className={styles.upperFirstLetter}
                      dangerouslySetInnerHTML={{
                        __html: highlightToHtml(item._highlightResult.query.value),
                      }}
                    />
                  ) : (
                    <p className={styles.upperFirstLetter}>{item.query}</p>
                  )}

                  <button title={t("generic.fillQueryWith", { 0: item.query })} onClick={() => onTapAhead(item)}>
                    <Icon name="search-arrow" aria-hidden />
                  </button>
                </div>
              );
            },
          },
        };
      },
    });

    return [
      recentSearches,
      querySuggestions,
      productsPlugin(algoliaIndexId, width!, t("generic.viewAllProducts"), router.locale!, (newItem) =>
        recentSearches.data?.addItem({ id: newItem, label: newItem })
      ),
      popularPlugin(algoliaIndexId, width!, router.locale!),
    ];
  }, [algoliaIndexId, suggestionsLimit, width, t, router.locale]);

  const autocomplete = useMemo(() => {
    return createAutocomplete({
      onStateChange({ state }) {
        if (breakpoint == "mobile" && state.isOpen && state.query != "") {
          setOpen();
        }
        setAutocompleteState(state);
      },
      openOnFocus: true,
      plugins,
      onSubmit(params) {
        router.push(
          `/search?${algoliaIndexId}${encodeURIComponent("[query]")}=${encodeURIComponent(params.state.query)}`
        );
        onClose();
      },
      autoFocus: breakpoint == "desktop" ? true : false,
      reshape({ sourcesBySourceId, state }) {
        const {
          recentSearchesPlugin: recentSearches,
          querySuggestionsPlugin: querySuggestions,
          productsPlugin: products,
          popularPlugin: popular,
          ...rest
        } = sourcesBySourceId;

        const actualProducts = products?.getItems().length || 0;

        return [
          !state.query && recentSearches,
          querySuggestions,
          state.query && actualProducts > 0 && products,
          (!state.query || (state.query && actualProducts == 0)) && popular,
          ...Object.values(!rest),
        ].filter(Boolean);
      },
      insights: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plugins, breakpoint]);

  // handle focus input on mobile in order to show the panel
  useEffect(() => {
    if (isOpen && breakpoint == "mobile") {
      setTimeout(() => {
        document.getElementById("search-input")?.focus();
      }, 50);
    }
  }, [breakpoint, isOpen]);

  // reset query on close
  useEffect(() => {
    if (autocomplete && !isOpen) {
      autocomplete.setQuery("");
    }
  }, [isOpen, autocomplete]);

  return <AlgoliaContext.Provider value={{ autocomplete, autocompleteState }}>{children}</AlgoliaContext.Provider>;
};
